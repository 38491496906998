import React from 'react'
import './style.scss';

const systemTemporarilyUnavailable = () => (
    <div className="tds-container tds-col-md-5">
        <h1 className="tds-h1--small">We&rsquo;re sorry.</h1>
        <p className="tds-mt-xl tds-large">We have experienced a technical issue.</p>
        <hr className="content-divider tds-mt-xl shorten-divider" />
        <p className="tds-mt-xl tds-heading">We&rsquo;ve recorded the error and are working on correcting it. Please try again later.</p>
    </div>
);

export default systemTemporarilyUnavailable;
