import React from 'react';
import './style/style.scss';

class Card extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { title, styleClass, message} = this.props;
        return (
            <div className={styleClass + `card-box`} id="my-alert-info2" data-no-icon="true">
                <div className="tds-alert__inner-container">
                    <div className="tds-alert__inner-text">
                        <h2 className="tds-alert__title">{title}</h2>
                        <p>
                            {message}
                        </p>
                    </div>
                </div>
            </div>

        );
    }
}

export default Card