import React from 'react';
//import ReactDOM from 'react-dom';
import AgentTrackerService from '../../api/agentTrackerService';
import TableRow from './tableRow';
//import '@trv-tds/core/dist/js/modules/data-table/DataTable.js'
import Loader from '../common/loader';
import AgentContext from '../agentContext';
import EmptyTable from './emptyTable';
import Button from '../common/button';
import utils from '../../utils/WebStorage';
const sessionStorage = utils.getSessionInstance();

class StatusResults extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            agentData: [],
            isLoading: true,
            isEmpty: false,
            noData: false //Use This For Testing But Will Need to Change
        };
        this.doDateSort = this.doDateSort.bind(this);
        this.getAgentData = this.getAgentData.bind(this);
        this.refreshTable = this.refreshTable.bind(this);
    }
    static contextType = AgentContext;

    componentWillMount() {
								this.getAgentData();
				
    }

				qualtrics(){
					const qualtrics = (function(){var g=function(e,h,f,g){

						this.get=function(a){for(var a=a+"=",c=document.cookie.split(";"),b=0,e=c.length;b<e;b++){for(var d=c[b];" "==d.charAt(0);)d=d.substring(1,d.length);if(0==d.indexOf(a))return d.substring(a.length,d.length)}return null};
						
						this.set=function(a,c){var b="",b=new Date;b.setTime(b.getTime()+6048E5);b="; expires="+b.toGMTString();document.cookie=a+"="+c+b+"; path=/; "};
						
						this.check=function(){var a=this.get(f);if(a)a=a.split(":");
						else if(100!=e) "v"==h	&&(e=Math.random()>=e/100?0:100);
						// ,a=[h,e,0],this.set(f,a.join(":"));
						else return!0;var c=a[1];if(100==c)return!0;switch(a[0]){case "v":return!1;case "r":return c=a[2]%Math.floor(100/c),a[2]++,this.set(f,a.join(":")),!c}return!0};
						
						this.go=function(){if(this.check()){var a=document.createElement("script");a.type="text/javascript";a.src=g;document.body&&document.body.appendChild(a)}};
						
						this.start=function(){var t=this;"complete"!==document.readyState?window.addEventListener?window.addEventListener("load",function(){t.go()},!1):window.attachEvent&&window.attachEvent("onload",function(){t.go()}):t.go()};};
					
						
						try{(new g(100,"r","QSI_S_ZN_d5y09WnY8foqkfz","https://znd5y09wny8foqkfz-travelers.siteintercept.qualtrics.com/SIE/?Q_ZID=ZN_d5y09WnY8foqkfz")).start()}catch(i){}})();
					
						
				}
    mapAgentData(data) {
        let mappedData = {};
        mappedData.agencyname = data.agencyName;
        mappedData.location = data.agencyCity + ", " + data.agencyState;
        mappedData.lastactivity = new Date(data.lastChanged).toDateString();
        mappedData.datesort = data.lastChanged;
        mappedData.datestarted = new Date(data.dateStarted).toDateString();
        mappedData.applicationtype = data.typeofApplication;
        mappedData.contact = data.contact;
        mappedData.status = data.status;
        mappedData.progress = data.progress;
        return mappedData;
    }

    doDateSort(initialData) {
        return initialData.slice().sort((a, b) => new Date(b.lastChanged) - new Date(a.lastChanged));
    }

    getTableRows() {
        let rawData = this.doDateSort(this.state.agentData);
        let row = [];
        for (var i = 0; i < rawData.length; i++) {
            let mappedData = this.mapAgentData(rawData[i]);
            row[i] = <TableRow agencyname={mappedData.agencyname} agencylocation={mappedData.location} lastactivity={mappedData.lastactivity} datestarted={mappedData.datestarted} applicationtype={mappedData.applicationtype} contact={mappedData.contact} status={mappedData.status} progress={mappedData.progress} datesort={mappedData.datesort} />
        }
        return row;
    }

    componentDidMount() {
					this.qualtrics();
    }

    refreshTable(){
        var email = sessionStorage.getAsString("EMAIL");
        var attrValues = ("-_--_--_-" + email + "-_-");
        window.cmCreateElementTag("VERIFYSTATUS_REFRESH","AGENCY_ONBOARDING_CLICKS",attrValues);
        this.getAgentData();
    }

    getAgentData() {
        let data = {};
        this.setState({
            agentData: [],
            isLoading: true
        });
        data.email = sessionStorage.getAsString("EMAIL");
        AgentTrackerService.getAgentDetails(data).then(res => {
            if (res.data) {
                this.setState({
                    agentData: res.data.searchResults,
                    isLoading: false,
                    isEmpty: false
                });
                var attrValues = ("-_--_--_-" + data.email + "-_-");
                window.cmCreatePageviewTag("STATUS_AGENCY_ONBOARDING", "AGENCY_ONBOARDING", null, null, attrValues);
                return res.data.searchResults;
            } else {
                this.setState({
                    agentData: [],
                    isLoading: false,
                    isEmpty: true
                });
                var attrValues = ("-_--_--_-" + data.email + "-_-No data");
                    window.cmCreatePageviewTag("STATUS_AGENCY_ONBOARDING", "AGENCY_ONBOARDING", null, null, attrValues);
            }
            
        })
            .catch(err => {
                this.setState({
                    isLoading: false
                })
                var attrValues = ("-_--_--_-" + data.email + "-_-service down");
                window.cmCreatePageviewTag("STATUS_AGENCY_ONBOARDING", "AGENCY_ONBOARDING", null, null, attrValues);
                this.props.history.push('/technicalError');
            });
    }

    render() {
        return (
            <div className="tds-container tds-centered">
                {
                    this.state.isLoading ?
                        <div className="mid-page">
                            <Loader />
                        </div>
                        :
                        <div>
                            <div>
                                <h1 className="tds-h1--small tds-centered">Agency Onboarding Tracker</h1>                                
                                <p className="tds-left sub-label">Check the status of your agency/producer onboarding.</p>                    
                                </div>            
                            {this.state.isEmpty ?
                                <EmptyTable></EmptyTable>
                                :
                                <div className="tds-mt-xxl tds-col-12 table-center tds-data-table-scrollpanel">
                                    <table id="TableTest" ref={this.DataTable} className="tds-data-table tds-data-table--hd  tds-data-table--hilite-row tds-data-table--header-dividers" data-table-sort="simple" data-sort-comparer="customColumnSort">
                                        <thead>
                                            <tr role="row">
                                                <th scope="col" role="columnheader" aria-sort="none">
                                                    <button className="tds-data-table__sort-col-button">Agency</button>
                                                </th>
                                                <th scope="col" role="columnheader" aria-sort="descending">
                                                    <button className="tds-data-table__sort-col-button">Last Updated</button>
                                                </th>
                                                <th scope="col" role="columnheader" aria-sort="none">
                                                    <button className="tds-data-table__sort-col-button">Date Started</button>
                                                </th>
                                                <th scope="col" role="columnheader" aria-sort="none">
                                                    <button className="tds-data-table__sort-col-button">Producer/ Principal</button>
                                                </th>
                                                <th scope="col" role="columnheader" aria-sort="none">
                                                    <button className="tds-data-table__sort-col-button">Application Type</button>
                                                </th>
                                                <th scope="col" role="columnheader">
                                                    <button className="plain-header-btn plain-header-text">Status</button>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.getTableRows()}
                                        </tbody>
                                    </table>
                                </div>
                            }
                            <div className="tds-button-container  tds-centered">
                                <Button style="tds-button--tertiary tds-centered margin-top-large" text="Refresh" onClick={this.refreshTable} />
                            </div>

                        </div>
                }
            </div>
        );
    }
};

export default StatusResults;
