import React from 'react';
//import FormInput from '../common/formInput';
import Button from '../common/button';
import AgentTrackerService from '../../api/agentTrackerService';
import AgentContext from '../agentContext';
import utils from '../../utils/WebStorage';
import AlertMsg from '../common/alertplaceholder';
const sessionStorage = utils.getSessionInstance();

class OneTimePin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      pin: '',
      error: false,
      attempts: 7,
      remainingAttempts: true,
      errorMessage: '',
      isServiceRequested: false,
      cssStyle:''
    };
    this.pinValidationCheked = false;
    this.pinInput = React.createRef();
    this.handleTextChange = this.handleTextChange.bind(this);
    this.submit = this.submit.bind(this);
    this.validatePin = this.validatePin.bind(this);
    this.validateEmpty = this.validateEmpty.bind(this);
    this.restart = this.restart.bind(this);
    this.getSessionEmail = this.getSessionEmail.bind(this);
  }

  static contextType = AgentContext;
  
  componentDidMount() {
    const agent = this.context;
    this.pinInput.current.focus();
    var attrValues = ("-_--_--_-" + this.getSessionEmail() + "-_-");
    window.cmCreatePageviewTag("VERIFYCODE_AGENCY_ONBOARDING", "AGENCY_ONBOARDING", null, null, attrValues);
  }

  handleTextChange(event) {
    const pin = (event.target.validity.valid) ? event.target.value : this.state.pin;
    this.setState({
      pin
    }, () => {
      if (!this.validateEmpty()) {
        this.setState({
          error: false,
          cssStyle:''
        })
      } else {
        this.setState({
          error: true,
          errorMessage: 'Cannot be left blank.',
          cssStyle:"tds-field--error"
        })

      }
      if (this.state.pin.length > 0 && this.pinValidationCheked && !this.validatePin()) {
        this.setState({
          error: true,
          cssStyle:"tds-field--error",
          errorMessage: 'The code you entered does not match the verification code sent to you. Please try again.'
        })
      }
    })
  }

  validatePin() {
    this.pinValidationCheked = true;
    const regEx = /^([0-9]{6})$/
    return regEx.test(this.state.pin)
  }

  validateEmpty() {
    return (this.state.pin === '' ? true : false)
  }

  restart() {
    var attrValues = ("-_--_--_-" + this.getSessionEmail() + "-_-");
    window.cmCreateElementTag("VERIFYCODE_BACK", "AGENCY_ONBOARDING_CLICKS", attrValues);
    this.props.history.push('/');
  }

  navigateToNextPage() {
    var attrValues = ("-_--_--_-" + this.getSessionEmail() + "-_-");
    window.cmCreateElementTag("VERIFYCODE_CONTINUE", "AGENCY_ONBOARDING_CLICKS", attrValues);
    this.props.history.push('/statusResults');
  }

  submit(e) {
    if (e) {
      e.preventDefault();
    }


    if (this.validateEmpty()) {
      this.setState({
        error: true,
        errorMessage: 'Cannot be left blank.',
        cssStyle:"tds-field--error"
      }, () => {
        var attrValues = ("-_--_--_-" + this.getSessionEmail() + "-_-" + this.state.errorMessage);
        window.cmCreateElementTag("VERIFYCODE_CONTINUE", "AGENCY_ONBOARDING_CLICKS", attrValues);
      })
      return
    }
    if (this.validatePin()) {
      var data = {};
      data.email = this.getSessionEmail();
      data.onetimePin = this.state.pin;
      this.setState({
        isServiceRequested: true
      })

      AgentTrackerService.verifyPin(data).then(response => {
        if (response.data && response.data.isValid === true) {
          this.setState({ error: false,
            cssStyle:"",
           });
          this.navigateToNextPage();
        }        else if (response && (response.isPinExpired === true && response.isMaxAttemptsReached === true) || (response.isPinExpired === true && response.isMaxAttemptsReached === false)) { //8th attempt & 20 mins expiry
          this.setState({
            isBusy: false,
            error: true,
            cssStyle:"tds-field--error",
            errorMessage: 'The last verification code we sent you has expired. Please request a new verification code.',
            isServiceRequested: false
          });
          var errorMessage = "VERIFICATION CODE EXPIRED";
          var attrValues = ("-_--_--_-" + this.getSessionEmail() + "-_-" + errorMessage);
          window.cmCreateElementTag("VERIFYCODE_CONTINUE", "AGENCY_ONBOARDING_CLICKS", attrValues);
        } else {
          this.setState({
            attempts: this.state.attempts - 1,
            error: true,
            cssStyle:"tds-field--error",
            errorMessage: 'The code you entered does not match the verification code sent to you. Please try again.',
            isServiceRequested: false
          });
          var attrValues = ("-_--_--_-" + this.getSessionEmail() + "-_-OTP does not match");
          window.cmCreateElementTag("VERIFYCODE_CONTINUE", "AGENCY_ONBOARDING_CLICKS", attrValues);
          return;
        }
      }).catch(err => {
        this.setState({ isBusy: false });
        var attrValues = ("-_--_--_-" + this.getSessionEmail() + "-_-Service down");
        window.cmCreateElementTag("VERIFYCODE_CONTINUE", "AGENCY_ONBOARDING_CLICKS", attrValues);
        this.props.history.push('/technicalError');
      });
    } else {
      this.setState({
        error: true,
        cssStyle:"tds-field--error",
        errorMessage: 'The code you entered does not match the verification code sent to you. Please try again.',
      });
      var attrValues = ("-_--_--_-" + this.getSessionEmail() + "-_-OTP does not match");
      window.cmCreateElementTag("VERIFYCODE_CONTINUE", "AGENCY_ONBOARDING_CLICKS", attrValues);
    }
  }

  getSessionEmail() {
    return sessionStorage.getAsString("EMAIL");
  }

  render() {
    return (     
      <div className="tds-container tds-col-md-5">        
      <div>
          <h1 className="tds-h1--small">Enter your verification code</h1>
          {this.context.attemptReach ? <AlertMsg cssclass= {"tds-alert--error"} message = {this.context.errorMessage} /> : null}
          </div>
        {this.state.remainingAttempts ?
          <div>
            <p className="tds-left sub-label">Please enter the verification code we sent to your email address, <b>{this.getSessionEmail()}</b></p>
            <form onSubmit={this.submit}>
              <div className={`form-group tds-field ${this.state.cssStyle}`}>
                <label htmlFor="pin" className="tds-field__label">Verification Code</label>
                <input className="tds-field__input" ref={this.pinInput} id="pin" pattern="[0-9]*" text="Verification Code" maxLength="6" value={this.state.pin} aria-describedby="pin_help" aria-invalid={this.state.error}  onChange={this.handleTextChange}></input>
                {this.state.error ?
                  <div id="pin_help" className="tds-field__message">
                    <span className="tds-sr-only"> Alert: </span>
                    {this.state.errorMessage}
                  </div>
                  : null}
              </div>
              <div className="tds-button-container  tds-centered">
                <Button style="tds-button--primary tds-centered" isBusy={this.state.isServiceRequested} type="submit" text="Continue" />                
                <Button style="tds-button--tertiary tds-centered margin-top-large" text="Back" onClick={this.restart} />
              </div>
            </form>
          </div>
          :
          <div className="tds-mt-xl tds-centered">            
            <p className="tds-h4--small tds-centered"> You have exceeded your attempts. Please generate another pin.</p>
            <Button style="tds-button--primary tds-centered" text="Regenerate Pin" onClick={this.restart}></Button>

          </div>
        }        
      </div>
    );
  }
};

export default OneTimePin;
