import React from 'react';
import './style.scss';

const PageUnavailabe = () => (
    <div className="tds-container tds-col-md-5">
        <h1 className="tds-h1--small">We&rsquo;re sorry.</h1>
        <p className="tds-mt-xl tds-large">We could not find the page you are looking for. The address may have expired or is incorrect.</p>
        <hr className="content-divider tds-mt-xl shorten-divider" />
        <p className="tds-mt-xl tds-heading">We&rsquo;ve recorded the error to see if there is something we can improve.</p>
    </div>
);
export default PageUnavailabe;