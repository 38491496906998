import React from 'react';
import PropTypes from 'prop-types';

export default class Button extends React.Component {
  constructor(props) {
    super();
    const { type = 'button', ...passThroughProps } = props;
    this.state = { type, ...passThroughProps };
    this.handleClick = this.handleClick.bind(this);
  }
  
  handleClick() {
    if (this.props.trvAnalytics !== null && this.props.trvAnalytics !== undefined &&
            this.props.trvAnalytics !== '') {
    }
    if (typeof this.props.onClick === 'function') {
      this.props.onClick();
    }
  }
  render() {
    return (
      <button type={this.state.type} onClick={this.handleClick} tabIndex={this.props.tabIndex}
        aria-busy={this.props.isBusy} className={this.props.style} > {this.props.text}</button>
    );
  }
}

Button.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
};

