import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import VerifyIdentity from './components/verifyIdentity';
import OneTimePin from './components/oneTimePin';
import StatusResults from './components/statusResults';
import SessionTimeout from './components/error/sessionTimeout'
import SystemError from './components/error/systemError'
import PageUnavailabe from './components/error/pageUnavailable';
import SystemTempUnavail from './components/error/systemTemporarilyUnavailable';
import TechnicalError from './components/error/technicalError';
import PinAttemptError from './components/error/pinAttemptsError';
import SiteHealth from './components/siteHealth';
//import ProgressBar from './components/progressBar';

const createRoutes = () => (  
    <Router>
      <Route exact path="/" component={VerifyIdentity} />     
        <Route exact path="/enterPin" component={OneTimePin} />
        <Route exact path="/statusResults" component={StatusResults}/>
        <Route exact path= "/sessionTimeout" component={SessionTimeout}/>
        <Route exact path = "/systemError" component={SystemError}/>
        <Route exact path = "/pageUnavailable" component={PageUnavailabe}/>
        <Route exact path = "/systemTempUnavail" component={SystemTempUnavail}/>
        <Route exact path = "/technicalError" component={TechnicalError}/>
        <Route exact path = "/attemptError" component={PinAttemptError}/>
        <Route exact path = "/health" component={SiteHealth} />     
    </Router> 
);

export default createRoutes;
