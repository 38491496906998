import React from 'react';
import PropTypes from 'prop-types';

const AlertPlaceHolder = (props) => {
  const additionalClass = props.message.indexOf('</') !== -1 ? 'linkAlert' : '';
  return (
    <div id="input-state-help" aria-atomic="true" aria-live="assertive" aria-relevant="all"
      className={[props.cssclass, additionalClass].join(' ')} role="alert">
      {props.message.indexOf('</') !== -1? (
        <span dangerouslySetInnerHTML={{ __html: props.message }} >
        </span> 
      )
        : (<span>{props.message}</span>)
      }
    </div >
  );
};

AlertPlaceHolder.propTypes = {
  cssclass: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired
};

export default AlertPlaceHolder;
