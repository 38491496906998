import React from "react";
import AgentTrackerService from "../../api/agentTrackerService";
import Card from "../common/card";
import Loader from "../common/loader";
import "./style.scss";

class SiteHealth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceRequested: false,
      otpSpinner: false,
      trackerSpinner: false,
      appSpinner: false,
      OTPHealth: "",
      TrackerHealth: "",
      AppHealth: "",
      otpServiceMessage: "",
      trackerServiceMessage: "",
      appServiceMessage: "",
      env: window.location.host
    };
    this.getCardStyle = this.getCardStyle.bind(this);
  }

  componentWillMount() {
    this.setState({
      otpServiceMessage:
        "Service check done on : " + new Date().toLocaleString(),
      trackerServiceMessage:
        "Service check done on : " + new Date().toLocaleString(),
      appServiceMessage:
        "Service check done on : " + new Date().toLocaleString(),
      isButtonRequired: true
    });
  }

  getCardStyle(status) {
    let style = "";
    switch (status) {
      case "success":
        style = "tds-alert--success";
        break;
      case "error":
        style = "tds-alert--error";
        break;
      case "info":
        style = "tds-alert--info";
        break;
    }
    return style + " tds-alert--has-inner-icon ";
  }

  componentDidMount(urlType) {
        this.setState({ otpSpinner: true });
        AgentTrackerService.getOTPHealthDetails().then(res => {
          this.setState({
            OTPHealth: this.getCardStyle("success"),
            otpServiceMessage: "Service check done on : " + new Date().toLocaleString(),
            otpSpinner: false
          });
        }).catch(err => {
          this.setState({
            OTPHealth: this.getCardStyle("error"),
            otpServiceMessage: "Service check done on : " + new Date().toLocaleString(),
            otpSpinner: false
          });
        });
        this.setState({ trackerSpinner: true });
        AgentTrackerService.getTrackerHealthDetails().then(res => {
          this.setState({
            TrackerHealth: this.getCardStyle("success"),
            trackerServiceMessage: "Service check done on : " + new Date().toLocaleString(),
            trackerSpinner: false
          });
        }).catch(err => {
          this.setState({
            TrackerHealth: this.getCardStyle("error"),
            trackerServiceMessage: "Service check done on : " + new Date().toLocaleString(),
            trackerSpinner: false
          });
        });
        this.setState({ appSpinner: true });
        AgentTrackerService.getRecaptchaHealthDetails().then(res => {
          this.setState({
            AppHealth: this.getCardStyle("success"),
            appServiceMessage: "Service check done on : " + new Date().toLocaleString(),
            appSpinner: false
          });
        }).catch(err => {
          this.setState({
            AppHealth: this.getCardStyle("error"),
            appServiceMessage: "Service check done on : " + new Date().toLocaleString(),
            appSpinner: false
          });
        });
    }

  render() {
    return (
      <>
        <div>
          <br></br>
          <div>
            {this.state.serviceRequested ? (
              <div className="health-page-spinner">
                <Loader />
              </div>
            ) : (
              <div>
                <Card
                  styleClass={this.state.OTPHealth}
                  title={
                    `OTP service - ` +
                    this.state.OTPHealth.split(" ")[0].substr(
                      11,
                      this.state.OTPHealth.split(" ")[0].length
                    )
                  }
                  message={this.state.otpServiceMessage}
                />
                <Card
                  styleClass={this.state.TrackerHealth}
                  title={
                    `Tracker service - ` +
                    this.state.TrackerHealth.split(" ")[0].substr(
                      11,
                      this.state.TrackerHealth.split(" ")[0].length
                    )
                  }
                  message={this.state.trackerServiceMessage}
                />
                <Card
                  styleClass={this.state.AppHealth}
                  title={
                    `Recaptcha Service - ` +
                    this.state.AppHealth.split(" ")[0].substr(
                      11,
                      this.state.AppHealth.split(" ")[0].length
                    )
                  }
                  message={this.state.appServiceMessage}
                />
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default SiteHealth;
