import React from 'react';
import { Component } from 'react';
import  './style.scss';

class TechnicalError extends Component{
    render(){
        return( <div className="tds-container tds-col-md-5">
        <h1 className="tds-h1--small">We&rsquo;re sorry.</h1>
        <p className="tds-mt-xl tds-large">Something went wrong during the verification process. Please try again.</p>
        </div>);
    }
}

export default TechnicalError;