import React from 'react';
import Button from '../common/button';
import './style.scss';
import AgentTrackerService from '../../api/agentTrackerService';
import utils from '../../utils/WebStorage';
import AgentContext from '../agentContext';
import Recaptcha from 'react-recaptcha';
import { ReactComponent as InfoCircle124 } from '@trv-ebus/tds-icons/dist/assets/icons/functional/svg/info-circle-1-24.svg'
const session = utils.getSessionInstance();

export class VerifyIdentity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      isSubmitted: false,
      invalidEmail: false,
      recaptchaResponse: '',
      isServiceRequested: false,
      recaptchaCompleted: false,
      errorMessage: '',
      cssStyle:''
    };
    this.emailInput = React.createRef();
    this.recaptchaInstance = React.createRef();
    this.handleTextChange = this.handleTextChange.bind(this);
    this.submit = this.submit.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.navigateToNextPage = this.navigateToNextPage.bind(this);
    this.callback = this.callback.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
    this.triggerOtp = this.triggerOtp.bind(this);
    this.setSessionEmail = this.setSessionEmail.bind(this);
  }
  static contextType = AgentContext;


  componentDidMount() {
    this.emailInput.current.focus();
    window.cmCreatePageviewTag("VERIFYEMAIL_AGENCY_ONBOARDING ", "AGENCY_ONBOARDING", null, null);
  }

  handleTextChange(event) {
    this.setState({
      email: event.target.value
    }, () => {
      if (!this.validateEmail() && this.state.isSubmitted) {
        this.setState({
          invalidEmail: true
        });
      } else {
        this.setState({
          invalidEmail: false,
          cssStyle:''

        });
      }
    });
  }

  validateEmail() {
    const regEx = /^(?=^.{6,60}$)((^[a-zA-Z0-9])+(?!.*?[.]{2})([\w\-.])*)@((?!.*--)(?!.*?[.]{2})(?:[a-zA-Z0-9])+([a-zA-Z0-9\-.])*)\.([a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?)$/;
    return regEx.test(this.state.email.trim())
  }

  navigateToNextPage() {
    var attrValues = ("-_--_--_-" + this.state.email + "-_-");
    window.cmCreateElementTag("VERIFYEMAIL_CONTINUE", "AGENCY_ONBOARDING_CLICKS", attrValues);
    let email = this.state.email.trim();
    this.setSessionEmail(email);
    this.props.history.push('/enterPin')
  }

  setSessionEmail(email) {
    session.set("EMAIL", email);
  }

  submit(event) {
    event.preventDefault();

    this.setState({
      isSubmitted: true
    });
    if (this.validateEmail()) {      
      this.recaptchaInstance.execute();
    } else {
      this.setState({
        invalidEmail: true,
        errorMessage: "Must be valid email format", 
        cssStyle:"tds-field--error"   
      });      
      var attrValues = ("-_--_--_-" + this.state.email + "-_-" + "Must be valid email format"); //This should match the error message above
      window.cmCreateElementTag("VERIFYEMAIL_CONTINUE", "AGENCY_ONBOARDING_CLICKS", attrValues);
    }
  }

  callback() {
  }


  verifyCallback(response) {
    this.setState({
      recaptchaResponse: response,
      recaptchaCompleted: true
    });
    this.triggerOtp();
  }

  triggerOtp() {
    let data = {}
    data.email = this.state.email.trim();
    data.recaptchaResponse = this.state.recaptchaResponse;

    if (this.state.recaptchaCompleted === true) {
      this.setState({
        isServiceRequested: true
      })
      AgentTrackerService.generatePin(data).then(response => {
        var attrValues = ("-_--_--_-" + this.state.email + "-_-" + this.state.errorMessage);
        if (response.data.isOtpGenerated === true) {
          this.navigateToNextPage();
        }
        if (response.data.isOtpGenerated === false && response.data.isMaxResendAttemptsReached === true) {
          this.context.errorMessage = "You\'ve exceeded the number of new verification codes allowed. Please use the verification code that was last sent to you.";
          this.context.attemptReach = true;
          var attrValues = ("-_--_--_-" + this.state.email + "-_-" + "EXCEEDS THE OTP RESENDS");
          window.cmCreateElementTag("VERIFYEMAIL_CONTINUE","AGENCY_ONBOARDING_CLICKS", attrValues);
          this.navigateToNextPage();
        }
        else if (response.data.isOtpGenerated === false) {
          this.setState({ isBusy: false });
          if (response.data.operationMessage) {
            this.context.pinError = response.data.operationMessage;
          }
          else {
            this.context.pinError = "You’ve exceeded the number of new verification codes we allow you to send to yourself. Please use the verification code that was last sent to you or call 1-844-505-7208."
          }
          var attrValues = ("-_--_--_-" + this.state.email + "-_-" + "EXCEEDS THE OTP RESENDS");
          window.cmCreateElementTag("VERIFYEMAIL_CONTINUE", "AGENCY_ONBOARDING_CLICKS", attrValues);          
          this.props.history.push('/attemptError');
        }
      }).catch(err => {
        this.setState({ isBusy: false });
        var attrValues = ("-_--_--_-" + this.state.email + "-_-" + "SERVICE DOWN");
        window.cmCreateElementTag("VERIFYEMAIL_CONTINUE", "AGENCY_ONBOARDING_CLICKS", attrValues);
        this.props.history.push('/technicalError');
      });
    }
  }

  render() {
    return (

      <div className="tds-container tds-col-md-5">  
      {this.state.invalidEmail ? 
      <div className="tds-inline-alert--error">
					<div id="my-alert-error" tabIndex="-1">
						Please fix the issue below to proceed
					</div>
          </div> : null }  
      <div>
          <h1 className="tds-h1--small">Please enter the email address used in your appointment application</h1>
          </div>
        <div>
          <p className="tds-left sub-label">A verification code will be sent to this email address.</p>
          <form onSubmit={this.submit}>
            <div className={`form-group tds-field ${this.state.cssStyle}`}>

              <label htmlFor="email" className="tds-field__label">
                Email

              <span data-toggle="tooltip" role="button" className="tds-tooltip__container" aria-labelledby="button-description1">              
                  <InfoCircle124 className="tds-iconbutton" />

                  <div role="tooltip" id="button-description1" className="tds-tooltip">
                    <div className="tds-tooltip__indicator">
                      Please note that if the email
                      address you enter does not
                      match the email address you
                        used in your appointment
                        application, you will not be
                        able to view the status of
                        your onboarding.
                      </div>
                  </div>
                </span>
              </label>

              <input className="tds-field__input" ref={this.emailInput} id="email" text="Email" maxLength="60" aria-describedby="email_help" aria-invalid={this.state.invalidEmail} onChange={this.handleTextChange}></input>

              {this.state.invalidEmail ?                
                <div id="email_help"  className="tds-field__message">
                <span className="tds-sr-only"> Alert: </span>
                {this.state.errorMessage}
              </div>
                : null}
            </div>            
            <div className="tds-mt-xl tds-centered">
              <div className="tds-button-container">
                <Button type='submit' text='Continue' isBusy={this.state.isServiceRequested} tabIndex="5" style="tds-button--primary" ></Button>
                {<Recaptcha
                  ref={e => this.recaptchaInstance = e}
                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                  size="invisible"
                  render="explicit"
                  onloadCallback={this.callback}
                  verifyCallback={this.verifyCallback}
                />}
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
};

export default VerifyIdentity;
