const getMemoryStorage = () => {
    return {
      data: {},
      getItem: function (key) {
        return this.data[key];
      },
      setItem: function (key, value) {
        this.data[key] = value;
      },
      removeItem: function (key) {
        delete this.data[key];
      },
      removeAll: function () {
        this.data = {};
      }
    };
  };
  
  class WebStorage {
    constructor (storage) {
      this.storageImpl = storage;
      try {
        const testKey = 'test-key';
        this.set(testKey, '1');
        this.remove(testKey);
      } catch (error) {
        this.storageImpl = getMemoryStorage();
      }
    }
  
    get (key) {
      const value = this.storageImpl.getItem(key);
      return value ? JSON.parse(value) : value;
    }
  
    getAsString (key) {
      return this.storageImpl.getItem(key);
    }
  
    set (key, value) {
      return this.storageImpl.setItem(key, typeof value !== 'string' ? JSON.stringify(value) : value);
    }
  
    remove (key) {
      return this.storageImpl.removeItem(key);
    }
  
    removeAll () {
      return this.storageImpl.clear();
    }
  }
  
  let _localInstance;
  let _sessionInstance;
  
  export default {
  
    getInstance: () => {
      if (!_localInstance) {
        const storage = typeof window === 'undefined' ? {} : window.localStorage || {};
        _localInstance = new WebStorage(storage);
      }
      return _localInstance;
    },
  
    getSessionInstance: () => {
      if (!_sessionInstance) {
        const storage = typeof window === 'undefined' ? {} : window.sessionStorage || {};
        _sessionInstance = new WebStorage(storage);
      }
      return _sessionInstance;
    },
  
    // unit testing only
    reset: () => {
      _localInstance = _sessionInstance = null;
    }
  };
  