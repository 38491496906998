import React from 'react';
import { Component } from 'react';
import  './style.scss';

class SystemError extends Component{
    render(){
        return( <div className="tds-container tds-col-md-5">
        <h1 className="tds-h1--small">We&rsquo;re sorry.</h1>
        <p className="tds-mt-xl tds-large">We are currently unavailable. Please try back soon.</p>
        </div>);
    }
}

export default SystemError;