import React from 'react';
// import '@trv-tds/core/dist/js/modules/data-table/DataTable.js'


class EmptyTable extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="tds-col-8 empty-table">
                <table className="tds-data-table tds-data-table--hd  tds-data-table--hilite-row tds-data-table--header-dividers" data-table-sort="simple" data-sort-comparer="customColumnSort">
                    <thead>
                        <tr role="row">
                            <th width="300px" scope="col" role="columnheader" aria-sort="none">
                                <button disabled="true" className="tds-data-table__sort-col-button">Agency</button>
                            </th>
                            <th width="100px" scope="col" role="columnheader" aria-sort="none">
                                <button disabled="true" className="tds-data-table__sort-col-button">Last Updated</button>
                            </th>
                            <th width="100px" scope="col" role="columnheader" aria-sort="none">
                                <button disabled="true" className="tds-data-table__sort-col-button">Date Started</button>
                            </th>
                            <th width="283px" scope="col" role="columnheader" aria-sort="none">
                                <button disabled="true" className="tds-data-table__sort-col-button">Producer/ Principal</button>
                            </th>
                            <th width="197px" scope="col" role="columnheader" aria-sort="none">
                                <button disabled="true" className="tds-data-table__sort-col-button">Application Type</button>
                            </th>
                            <th  width="188px" scope="col" role="columnheader">
                                <button className="plain-header-btn plain-header-text">Status</button>
                            </th>
                        </tr>
                    </thead>
                </table>
                <br></br>
                <div className="tds-centered empty-text">
                If your appointment information is not displayed on this page, please contact your Travelers representative.
                </div>
            </div>
        )

    }
};

export default EmptyTable;

