import React from 'react';
import Helmet from "react-helmet";
import createRoutes from './routes';
import { Header, Footer} from 'trv-tds-react';
//import Footer from './components/footer';
import './styles/app.scss';
import {AgentProvider} from './components/agentContext'

function App() {
  const routes = createRoutes();
  let agentContext = {email:''}
  return (
    <AgentProvider value={agentContext}>
      <div className="tds-app-wrapper">
        <Helmet>
          <meta name="robots" content="noindex"></meta>

        

        </Helmet>
        <Header href="https://travelers.com" appName="Agency Onboarding Tracker" className="tds-global-header__product">
        </Header>
        <div className="tds-body tds-mt-xxxl">
          {routes}
        </div>
        <Footer>
            <Footer.Copy/>
            <div className="tds-global-footer__links">
            <ul>
                <li><a id='termsofservice' className="tds-global-footer__link-item" href={`https://www.travelers.com/w3c/legal/`} target="termsofservice">Terms of Service</a></li>
                <li><a id='privacyandsecurity' className="tds-global-footer__link-item" href={`https://www.travelers.com/privacy-statements/`} target="privacysecurity">Privacy &amp; Security</a></li>
                <li><a id='accessibility' className="tds-global-footer__link-item" href={`https://www.travelers.com/about-travelers/accessibility/`} target="accessibility">Accessibility</a></li>
                </ul>
            </div>
        </Footer>
      </div>
    </AgentProvider>


  );
}

export default App;
