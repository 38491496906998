import React from 'react';
import  './style.scss';
import { Component } from 'react';


class SessionTimeout extends Component{
    componentDidMount() {
        
    }
    render(){
        return( <div className="tds-container tds-col-md-5">
        <h1 className="tds-h1--small">We&rsquo;re sorry.</h1>
        <p className="tds-mt-xl tds-large">For your security, your session has expired and your information has not been saved.</p>
    </div>);
    }
}
export default SessionTimeout;