import Axios from 'axios';

class agentTrackerService {
  static generatePin(data) {
    return new Promise((resolve, reject) => {
      Axios.post(`/api/otp/send`, {
        email: data.email,
        recaptchaResponse: data.recaptchaResponse
      }).then(response => {
        resolve(response.data);
      }).catch(err => {
        reject(err);
      });
    });
  }

  static verifyPin(data) {
    return new Promise((resolve, reject) => {
      Axios.post(`/api/otp/verify`, {
        email: data.email,
        onetimePin: data.onetimePin
      }).then(response => {
        resolve(response.data);
      }).catch(err => {
        reject(err);
      });
    });
  }

  static getAgentDetails(data) {
    return new Promise((resolve, reject) => {
      Axios.post(`/api/agentstatus/getStatus`, {
        email: data.email
      }).then(response => {
        resolve(response.data);
      }).catch(err => {
        reject(err);
      });
    });
  }

  static getOTPHealthDetails(){
    return new Promise((resolve,reject) => {
      Axios.get('/api/sitehealth/otp', {
      }).then(response => {
        resolve(response.data);
      }).catch(err => {
        reject(err);
      });
    });
  }


  static getTrackerHealthDetails(){
    return new Promise((resolve,reject) => {
      Axios.get('/api/sitehealth/tracker', {
      }).then(response => {
        resolve(response.data);
      }).catch(err => {
        reject(err);
      });
    });
  }

  static getRecaptchaHealthDetails(){
    return new Promise((resolve,reject) => {
      Axios.get('/api/sitehealth/recaptcha', {
      }).then(response => {
        resolve(response.data);
      }).catch(err => {
        reject(err)
      });
    });
  }


}

export default agentTrackerService;
