import React from 'react';
import './style.scss';
//import AgentTrackerService from '../../api/agentTrackerService';
//import { tsThisType } from '@babel/types';
import ProgressBar from '../progressBar';

export default class TableRow extends React.Component {
    constructor(...props) {
        super(...props);
        this.state = {
        };
    }

    getAgencyNameCell(agencyName, agencyLocation){
        return(
            <div>
                <div className="field agency name">
                    {agencyName}
                </div>
                <div className="field agency location ">
                    {agencyLocation}
                </div>
            </div>
        );
    }

    formatDate(dateInput){
        var date = new Date(dateInput);
        var month = date.toLocaleString("en-us", { month: "short"});
        var day = date.getDate();
        var year = date.getFullYear();
        var newDate = month+' '+day+', '+year; //MMM D, YYYY
        return newDate;
    }

    getLastUpdatedByCell(lastactivity, datestarted){
        if(datestarted && datestarted!==null){
            return(
                <div>
                    <div className="field date last-updated">
                        {this.formatDate(lastactivity)}
                    </div>                    
                </div>
            );
        } else{
            return(
                <div className="field date last-updated">
                    {this.formatDate(lastactivity)}
                </div>
            );
        }
    }

    getStatusCell(status, percent){
        return(           
            <div class="tds-progress">
                <progress class="tds-progress__indicator" max="100" value={percent} aria-live="assertive"></progress>
                <label class="tds-progress__label">{status}</label> 
            </div>            
        );
    }
    
    render() {
        const { agencyname, agencylocation, lastactivity, datestarted, applicationtype, contact, status, progress, datesort } = this.props;
        return (
            <tr role="row">
                <td className="tds-data-table__cell--align-left" data-sort-value={agencyname} data-sort-data-type="string">{this.getAgencyNameCell(agencyname, agencylocation)}</td>
                <td className="tds-data-table__cell--align-left field date last-updated" data-sort-value={datesort} data-sort-data-type="date">{this.formatDate(lastactivity)}</td>
                <td className="tds-data-table__cell--align-left field date date-started" data-sort-value={datesort} data-sort-data-type="date">{this.formatDate(datestarted)}</td>
                <td className="tds-data-table__cell--align-left field producer" data-sort-value={contact} data-sort-data-type="string">{contact}</td>
                <td className="tds-data-table__cell--align-left field application" data-sort-value={applicationtype} data-sort-data-type="string">{applicationtype}</td>
                <td className="tds-data-table__cell--align-left field status" data-sort-value={progress} data-sort-data-type="string">{this.getStatusCell(status, progress)}</td>
            </tr> 
        );
    }
};