import React from 'react';
import { Component } from 'react';
import  './style.scss';
import AgentContext from '../agentContext';

class PinAttemptError extends Component{
    static contextType = AgentContext;

    render(){
        return( 
        <div className="tds-container tds-col-md-5">
            <h1 className="tds-h1--small">We&rsquo;re sorry.</h1>
            <p className="tds-mt-xl tds-large">{this.context.pinError}</p>
            <hr className='tds-mt-xl content-divider'></hr>
        </div>
        );
    }
}

export default PinAttemptError;